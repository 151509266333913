import React from "react"
import Topbar from "../../components/organisms/topbar"
import CustomLayout from "../../Layouts/Layout"
import CommodityManagement from "./commodity-management"
import "./style.scss"

const IndexPage = ({ location }) => {
  const openTab = {
    tab: location?.state?.tab,
  }
  return (
    <div className="company-page">
      <Topbar />
      <CustomLayout
        selectedPage={
          <CommodityManagement openTab={openTab} location={location} />
        }
      />
    </div>
  )
}

export default IndexPage
